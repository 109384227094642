import { MdClear } from "react-icons/md";
import AddUsers from "./AddUsers";
import SelectCampaign from "./SelectCampaign";
import Stepper from "./Stepper";
import { useState } from "react";
import useCampaigns from "../useCampaign";

const CreateCampaign = ({ onClose, handleSnackbar }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    existingCampaign: "",
    campaignName: "",
    couponCode: "",
    startDate: "",
    endDate: "",
    selectedVendor: null,
    selectedUsers: [],
  });

  const { submitCampaign, updateCampaign } = useCampaigns();

  const validateForm = () => {
    if (selectedOption === "existing") return !!formData.existingCampaign;

    const isStepOneValid =
      formData.campaignName.trim().length >= 3 &&
      (!formData.couponCode || formData.couponCode.length >= 4) &&
      formData.startDate &&
      new Date(formData.startDate) >= new Date() &&
      formData.endDate &&
      new Date(formData.endDate) > new Date(formData.startDate) &&
      formData.selectedVendor;

    const isStepTwoValid = formData.selectedUsers.length > 0;

    return currentStep === 1 ? isStepOneValid : isStepOneValid && isStepTwoValid;
  };

  const handleNextStep = () => {
    if (validateForm()) setCurrentStep(2);
  };

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };

  const handleSubmit = async () => {
    try {
      let response = null;
      if (formData.existingCampaign) {
        response = await updateCampaign(formData.existingCampaign.value, { users: formData.selectedUsers });
        if (response) handleSnackbar("Your Campaign has been updated successfully!")
      } else {
        response = await submitCampaign({
          name: formData.campaignName,
          couponCode: formData.couponCode || undefined,
          startDate: new Date(formData.startDate).toISOString(),
          endDate: new Date(formData.endDate).toISOString(),
          vendor: formData.selectedVendor.value,
          users: formData.selectedUsers,
        });
        if (response) handleSnackbar("Your Campaign has been created successfully!")
      }
      if (!response) handleSnackbar("An unexpected error occured", "error")
      onClose();
    } catch (error) {
      console.error("Error submitting campaign:", error);
      handleSnackbar(`An unexpected error occured: ${error?.message ?? error}`, "error")
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg">
      {/* Header */}
      <div className="flex justify-between items-center bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500 p-4 rounded-t-lg">
        <h2 className="text-2xl font-bold text-white">Create Campaign</h2>
        <button onClick={onClose} className="text-xl text-white">
          <MdClear />
        </button>
      </div>

      {/* Content */}
      <div className="p-4 md:p-6">
        <Stepper currentStep={currentStep} />
        {currentStep === 1 ? (
          <SelectCampaign
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            formData={formData}
            setFormData={setFormData}
          />
        ) : (
          <AddUsers formData={formData} setFormData={setFormData} />
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          {currentStep === 2 && (
            <button
              type="button"
              onClick={handlePreviousStep}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
            >
              Previous
            </button>
          )}
          {currentStep === 1 ? (
            <button
              type="button"
              onClick={handleNextStep}
              disabled={!validateForm()}
              className={`px-4 py-2 rounded-md ${validateForm()
                ? "bg-blue-500 text-white hover:bg-blue-600"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              disabled={!validateForm()}
              onClick={handleSubmit}
              className={`px-4 py-2 rounded-md ${validateForm()
                ? "bg-green-500 text-white hover:bg-green-600"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCampaign;
