import { useState, useEffect } from "react";
import Select from "react-select";
import useCampaigns from "../useCampaign";
import Papa from "papaparse";

const UserProfileOptions = [
  { value: "ENTREPRENEUR", label: "Entrepreneur" },
  { value: "INVESTOR", label: "Investor" },
  { value: "MOVER_SHAKER", label: "Mover & Shaker" },
  { value: "NONE", label: "None" },
];

const AddUsers = ({ formData, setFormData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [role, setRole] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { fetchUsers, fetchCouponUser } = useCampaigns();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchUsers(searchTerm, role?.value);
        setUsers(data);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm, role]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {

        const data = await fetchCouponUser(formData.existingCampaign.value);
        const selectedUser = data.map(couponData => couponData?.user._id);

        setFormData((prev) => ({
          ...prev,
          selectedUsers: selectedUser
        }));

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (formData.existingCampaign) {
      fetchData();
    }

  }, [])
  const handleCheckboxChange = (userId) => {
    setFormData((prev) => ({
      ...prev,
      selectedUsers: prev?.selectedUsers?.includes(userId)
        ? prev.selectedUsers.filter((id) => id !== userId)
        : [...(prev.selectedUsers || []), userId],
    }));
  };

  const handleReset = () => {
    setSearchTerm("");
    setRole(null);
    // setFormData((prev) => ({ ...prev, selectedUsers: [] }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      complete: (result) => {
        const emails = result.data.flat();
        const validUsers = users.filter((user) => emails?.includes(user.email));
        setFormData((prev) => ({
          ...prev,
          selectedUsers: [...new Set([...prev.selectedUsers, ...validUsers.map((u) => u._id)])],
        }));
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  const generateSampleCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," +
      ["email", "user1@example.com", "user2@example.com", "user3@example.com", "user4@example.com"]
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "User_Sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>

      {/* Upload CSV */}
      <div className="mb-4 flex flex-wrap justify-between items-center gap-4">
        <h2 className="text-xl font-semibold">Add Users</h2>
        <div className="flex flex-col-reverse items-center gap-1">
          <a
            onClick={generateSampleCSV}
            download="sample.csv"
            className="text-sm text-blue-500 hover:text-blue-700 hover:underline cursor-pointer"
          >
            View Sample CSV
          </a>
          <label className="px-4 py-2 bg-blue-500 text-white rounded-md cursor-pointer shadow-md hover:bg-blue-600">
            Upload CSV
            <input type="file" accept=".csv" onChange={handleFileUpload} className="hidden" />
          </label>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
        <div className="flex flex-1 flex-wrap gap-4">
          <input
            type="text"
            placeholder="Search by email or name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          <Select
            options={UserProfileOptions}
            value={role}
            onChange={setRole}
            placeholder="Select Role"
            classNamePrefix="react-select"
            isSearchable
            className="flex-1"
          />
        </div>
        <button
          onClick={handleReset}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md shadow-md hover:bg-gray-400"
        >
          Reset
        </button>
      </div>

      <div className="text-sm font-bold shadow-sm mb-2">
        Total Users Selected: {formData.selectedUsers.length}
      </div>

      {/* User Table */}
      {!loading && users.length > 0 && (
        <div className="overflow-y-auto max-h-60">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left">Select</th>
                <th className="px-4 py-2 text-left">First Name</th>
                <th className="px-4 py-2 text-left">Last Name</th>
                <th className="px-4 py-2 text-left">Email</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id} className="border-t">
                  <td className="px-4 py-2">
                    <input
                      type="checkbox"
                      checked={formData.selectedUsers?.includes(user._id)}
                      onChange={() => handleCheckboxChange(user._id)}
                    />
                  </td>
                  <td className="px-4 py-2">{user.firstName}</td>
                  <td className="px-4 py-2">{user.lastName}</td>
                  <td className="px-4 py-2">{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loading && users.length === 0 && (
        <p className="text-gray-500">No users found.</p>
      )}

    </div>
  );
};

export default AddUsers;
