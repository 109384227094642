import React, { useState } from "react";
import Select from "react-select";
import { TextField } from "@material-ui/core";

const CampaignFilter = ({ filters, setFilters, filtersConfig, onApplyFilters }) => {

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    onApplyFilters(filters);
  };

  const clearFilters = () => {
    setFilters({});
    onApplyFilters({});
  };

  return (
    <div className="p-4 bg-transparent w-full">
      <div className="flex flex-wrap gap-4 justify-center md:justify-start">
        {filtersConfig.map((filter) => {
          if (filter.type === "dropdown") {
            return (
              <div key={filter.name} className="min-w-[200px] w-full max-w-[300px]">
                <Select
                  isMulti={filter.multiple}
                  options={filter.options}
                  value={filter.options.filter(option =>
                    Array.isArray(filters[filter.name])
                      ? filters[filter.name].includes(option.value)
                      : filters[filter.name] === option.value
                  )}
                  onChange={(selectedOptions) =>
                    handleFilterChange(
                      filter.name,
                      filter.multiple
                        ? selectedOptions.map((option) => option.value)
                        : selectedOptions?.value || ""
                    )
                  }
                  placeholder={filter.placeholder}
                  classNamePrefix="react-select"
                />
              </div>
            );
          }
          if (filter.type === "text") {
            return (
              <TextField
                key={filter.name}
                type="text"
                label={filter.placeholder}
                value={filters[filter.name] || ""}
                onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                variant="outlined"
                size="small"
                sx={{ minWidth: 200, width: "100%", maxWidth: 300 }}
                style={{ background: "hsl(0, 0%, 100%)" }}
              />
            );
          }
          if (filter.type === "datetime-local") {
            return (
              <TextField
                key={filter.name}
                type="datetime-local"
                label={filter.placeholder}
                value={filters[filter.name] || ""}
                onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                variant="outlined"
                size="small"
                sx={{ minWidth: 200, width: "100%", maxWidth: 300 }}
                InputLabelProps={{ shrink: true }}
                style={{ background: "hsl(0, 0%, 100%)" }}
              />
            );
          }
          return null;
        })}

        <div className="flex flex-wrap gap-2 mt-4 w-full justify-center md:justify-start">
          <button
            onClick={applyFilters}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 w-full md:w-auto"
          >
            Apply Filters
          </button>
          <button
            onClick={clearFilters}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 w-full md:w-auto"
          >
            Clear Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampaignFilter;
