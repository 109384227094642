import { useState, useCallback } from "react";

const useCampaigns = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem("accessToken");

    const fetchData = useCallback(async (url, options = {}) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${accessToken}`, ...options.headers },
                ...options,
            });

            if (!response.ok) throw new Error(response.statusText);
            return await response.json();
        } catch (err) {
            setError(err.message || "An unexpected error occurred");
            return null;
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const fetchCampaigns = async (params = {}) => {
        const url = new URL(`${API_BASE_URL}/api/v1/campaign`);
        Object.entries(params).forEach(([key, value]) => {
            if (value) url.searchParams.append(key, value);
        });

        const data = await fetchData(url);
        return data?.data;
    };


    const fetchVendors = async (name = "") => {
        const url = new URL(`${API_BASE_URL}/api/v1/companies`);
        if (name) url.searchParams.append("name", name);
        const data = await fetchData(url);
        return data?.data;
    };

    const fetchUsers = async (keyword = "", role = "") => {
        const url = new URL(`${API_BASE_URL}/api/v1/campaign/users`);
        if (keyword) url.searchParams.append("keyword", keyword);
        if (role) url.searchParams.append("role", role);
        const data = await fetchData(url);
        return data?.data;
    };

    const submitCampaign = async (formData) => {
        return await fetchData(`${API_BASE_URL}/api/v1/campaign`, {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(formData),
        });
    };

    const updateCampaign = async (campaignId, data) => {
        return await fetchData(`${API_BASE_URL}/api/v1/campaign/${campaignId}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(data),
        });
    };

    const fetchCouponUser = async (campaignId) => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/campaign/${campaignId}/users`);
        return data?.data;
    };

    return {
        loading,
        error,
        fetchCampaigns,
        fetchVendors,
        fetchUsers,
        submitCampaign,
        updateCampaign,
        fetchCouponUser,
    };
};

export default useCampaigns;
