const Stepper = ({ currentStep }) => {
  return (
    <div className="flex justify-between items-center mb-6">
      <div
        className={`flex items-center space-x-2 ${currentStep === 1 ? "font-semibold" : "text-gray-400"
          }`}
      >
        <span className="w-6 h-6 flex items-center justify-center rounded-full bg-blue-500 text-white">
          1
        </span>
        <span>Select Campaign</span>
      </div>
      <div className="flex-1 h-px bg-gray-300 mx-4"></div>
      <div
        className={`flex items-center space-x-2 ${currentStep === 2 ? "font-semibold" : "text-gray-400"
          }`}
      >
        <span className="w-6 h-6 flex items-center justify-center rounded-full bg-blue-500 text-white">
          2
        </span>
        <span>Add Users</span>
      </div>
    </div>
  );
};

export default Stepper;

