import Select from 'react-select';
import useCampaigns from "../useCampaign";
import { useEffect, useState } from "react";

const SelectCampaign = ({ selectedOption, setSelectedOption, formData, setFormData }) => {
  const { fetchVendors, fetchCampaigns } = useCampaigns();
  const [autoGenerateCoupon, setAutoGenerateCoupon] = useState(true);
  const [campaigns, setCampaigns] = useState([])
  const [vendors, setVendors] = useState([])

  const loadOptions = async () => {
    const vendors = await fetchVendors();
    const vendorOptions = vendors?.map((vendor) => ({ value: vendor._id, label: vendor.name }));
    setVendors(vendorOptions)
  };

  const loadCampaignOptions = async () => {
    const campaigns = await fetchCampaigns({ skip: "0", limit: "1000" });
    const campaignOptions = campaigns?.data?.map((campaign) => ({ value: campaign._id, label: campaign.name }));
    setCampaigns(campaignOptions)
  };

  useEffect(() => {
    loadOptions()
    loadCampaignOptions()
  }, [])
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFormData({
      existingCampaign: "",
      campaignName: "",
      couponCode: "",
      startDate: "",
      endDate: "",
      selectedVendor: null,
      selectedUsers: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-6">Select Campaign</h2>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Choose an option:
        </label>
        <div className="mt-2 space-y-2">
          {["existing", "new"].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="radio"
                name="campaign-option"
                value={option}
                checked={selectedOption === option}
                onChange={() => handleOptionChange(option)}
                className="form-radio h-4 w-4 text-blue-500"
              />
              <span>
                {option === "existing" ? "Select Existing Campaign" : "Create New Campaign"}
              </span>
            </label>
          ))}
        </div>
      </div>

      {selectedOption === "existing" && (
        <div className="mb-4">
          <label htmlFor="existing-campaign" className="block text-sm font-medium text-gray-700">
            Select Existing Campaign <span className="text-red-500">*</span>
          </label>
          <Select
            options={campaigns}
            value={formData.existingCampaign}
            onChange={(selected) => setFormData((prev) => ({ ...prev, existingCampaign: selected }))}
            placeholder="Select a Campaign"
            classNamePrefix="react-select"
            isSearchable
          />
        </div>
      )}

      {selectedOption === "new" && (
        <div>
          {['campaignName', 'startDate', 'endDate'].map((field) => (
            <div key={field} className="mb-4">
              <label htmlFor={field} className="block text-sm font-medium text-gray-700">
                {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())} <span className="text-red-500">*</span>
              </label>
              <input
                type={field.includes("Date") ? "datetime-local" : "text"}
                defaultValue={field.includes("Date") ? new Date().toISOString().slice(0, 16) : ""}
                min={field.includes("Date") ? new Date().toISOString().slice(0, 16) : undefined}
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          ))}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Select Vendor <span className="text-red-500">*</span>
            </label>
            <Select
              options={vendors}
              value={formData.selectedVendor}
              onChange={(selected) => setFormData((prev) => ({ ...prev, selectedVendor: selected }))}
              placeholder="Select Vendor"
              classNamePrefix="react-select"
              isSearchable
            />
          </div>

          <div className="mb-4 flex items-center space-x-2">
            <input
              type="checkbox"
              id="auto-generate-coupon"
              checked={autoGenerateCoupon}
              onChange={() => {
                setAutoGenerateCoupon(!autoGenerateCoupon)
                setFormData((prev) => ({ ...prev, couponCode: "" }));
              }}
              className="form-checkbox h-4 w-4 text-blue-500"
            />
            <label htmlFor="auto-generate-coupon" className="text-sm font-medium text-gray-700">
              Auto Generate Coupon Code
            </label>
          </div>

          {!autoGenerateCoupon && (
            <div className="mb-4">
              <label htmlFor="couponCode" className="block text-sm font-medium text-gray-700">
                Coupon Code
              </label>
              <input
                type="text"
                id="couponCode"
                name="couponCode"
                value={formData.couponCode}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectCampaign;
