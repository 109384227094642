import React, { useEffect, useState } from 'react'
import { MdAddToPhotos } from 'react-icons/md';
import CreateCampaign from './components/CreateCampaign';
import CampaignFilter from './components/CampaignFilter';
import CampaignTable from './components/CampaignTable';
import useCampaigns from './useCampaign';
import Loader from 'components/loader';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function Campaigns() {

  const [campaigns, setCampaigns] = useState([])
  const [campaignsMeta, setCampaignsMeta] = useState(null)
  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = React.useState(false);
  const [filters, setFilters] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const { fetchCampaigns, loading } = useCampaigns();

  const handleSnackbar = (newMessage, severity = "success") => {
    setSnackbar({ open: true, message: newMessage, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    setSnackbar({ ...snackbar, open: false });
  };

  const filtersConfig = [
    {
      type: "text",
      name: "name",
      placeholder: "Search Campaign",
    },
    {
      type: "text",
      name: "vendor",
      placeholder: "Search Vendor",
    },
    {
      type: "dropdown",
      name: "status",
      options: [{ label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }],
      isMulti: false,
      placeholder: "Select Status",
    },
    {
      name: "startDate",
      type: "datetime-local",
      placeholder: "Start Date",
    },
    {
      name: "endDate",
      type: "datetime-local",
      placeholder: "End Date",
    }
  ];

  const getCampaigns = async (params) => {
    const data = await fetchCampaigns(params)
    setCampaigns([...data.data])
    setCampaignsMeta(data.meta)
  }

  useEffect(() => {
    getCampaigns({ skip: "0", take: "10" })
  }, [isCreateCampaignOpen])

  return (
    loading ? <div className="h-screen w-full">
      <Loader size={16} />
    </div> : (
      <div className="mt-8">
        {!isCreateCampaignOpen && <>
          <div className="flex flex-row-reverse">
            <button
              onClick={() => {
                setIsCreateCampaignOpen(true)
              }}
              className="flex justify-between items-center gap-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              <MdAddToPhotos /> Create Campaign
            </button>
          </div>

          <CampaignFilter
            filters={filters}
            setFilters={setFilters}
            filtersConfig={filtersConfig}
            onApplyFilters={(data) => { getCampaigns({ name: data?.name, vendor: data?.vendor, status: data?.status, startDate: data?.startDate, endDate: data?.endDate, skip: "0", take: "10" }) }}
          />

          <div className="overflow-x-auto" style={{ maxWidth: "100%" }}>
            <table style={{ minWidth: "100%" }} className="w-full table-auto">
              <CampaignTable
                tableData={campaigns}
                handleItemsPerPageChange={(e) => { getCampaigns({ skip: "0", take: e.target.value }) }}
                handlePageChange={(e, newPage) => { getCampaigns({ skip: campaignsMeta.take * (newPage - 1), take: campaignsMeta.take }) }}
                currentPage={campaignsMeta?.currentPage}
                totalCount={campaignsMeta?.totalCount}
                itemsPerPage={campaignsMeta?.take}
              />
            </table>
          </div>

        </>
        }
        {isCreateCampaignOpen && <CreateCampaign onClose={() => { setIsCreateCampaignOpen(false) }} handleSnackbar={handleSnackbar} />}

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} variant="filled" sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

      </div>)
  )
}

export default Campaigns