import React, { use, useEffect } from "react";
import Card from "components/card";
import Pagination from "@material-ui/lab/Pagination";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useCampaigns from "../useCampaign";
import { FiDownload } from "react-icons/fi";

function CampaignTable(props) {
  const {
    tableData,
    handleItemsPerPageChange,
    handlePageChange,
    currentPage,
    totalCount,
    itemsPerPage,
  } = props;

  // Add columnHelper here
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          CAMPAIGN NAME
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("couponCode", {
      id: "couponCode",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          COUPON CODE
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() ?? "-"}
        </p>
      ),
    }),
    columnHelper.accessor("startDate", {
      id: "startDate",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          START DATE
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {new Date(info?.getValue())?.toLocaleString()}
        </p>
      ),
    }),
    columnHelper.accessor("endDate", {
      id: "endDate",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">END DATE</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {new Date(info?.getValue())?.toLocaleString()}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">STATUS</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("vendor", {
      id: "vendor",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          VENDOR
        </p>
      ),
      cell: (info) => {
        return (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info?.row.original.vendor?.name}
          </p>
        )
      },
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          ACTIONS
        </p>
      ),
      cell: (data) => (
        <div className="flex space-x-4 justify-start">
          <FiDownload

            className="cursor-pointer text-green-600 hover:text-blue-500 transition-all duration-200"
            title="Export Coupons"
            onClick={async () => {
              const row = await fetchCouponUser(data.row.original._id);
              const baseUrl = process.env.REACT_APP_API_URL;
              // Convert data to CSV format
              const csvRows = [
                "Email,Code,IsRedeemed,RedemptionDate", // CSV headers
                ...row.map((item) => [item.user.email, item.code, item.isRedeemed, item?.redeemedDate].join(",")), // CSV rows
                "", // Empty line before instructions
                "Instructions for using the API:",
                `1. Verify Coupon: Send a GET request to ${baseUrl}/api/v1/campaign/coupons/verify/${data.row.original._id} with email and code as query parameters.`,
                `   Example: GET ${baseUrl}/coupons/verify/123?email=user@example.com&code=COUPON123`,
                `2. Redeem Coupon: Send a POST request to ${baseUrl}/api/v1/campaign/coupons/redeem/${data.row.original._id} with email and code in the request body.`,
                `   Example: POST ${baseUrl}/coupons/redeem/123 { "email": "user@example.com", "code": "COUPON123" }`,
              ].join("\n"); // Join rows with new lines

              // Create a Blob with CSV content
              const blob = new Blob([csvRows], { type: "text/csv" });
              const url = URL.createObjectURL(blob);

              // Create a temporary link and trigger download
              const a = document.createElement("a");
              a.href = url;
              a.download = `${data.row.original.name}_Campaign_Codes.csv`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

              // Release the object URL
              URL.revokeObjectURL(url);

            }}
          />
        </div>
      ),
    }),
  ];

  const [sorting, setSorting] = React.useState([]);
  const [data, setData] = React.useState(() => [...tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  const { fetchCouponUser } = useCampaigns()


  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() ? (
                          <span>
                            {header.column.getIsSorted() === "asc" ? "↑" : "↓"}
                          </span>
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="mt-4 flex items-center justify-between">
          <Pagination
            count={Math.ceil(totalCount / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            size="small"
            showFirstButton
            showLastButton
          />
          <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </Card>
  );
}

export default CampaignTable;
